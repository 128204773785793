/* Applies to the entire body of the HTML document (except where overridden by more specific
selectors). */


h1 {
  font-weight: bold;
  text-decoration: underline;  
}

p {
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  font-size: 2vh;
}

  /* Expericnes styles */
  .backGroundUp {
    background-color: #ffcfb3;
    height: 100%;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
  }

  .backGroundSizeUp {
    height: 93.1vh;
    background-color: #ffcfb3;
  }

  .backColorUp{background-color: #ffcfb3;}

  .textFont {
    font-family: "Courier New", Courier, monospace;
  }

  .blankSpaceUp {
    height: 10vh;
    background-color: #ffcfb3;
  }


  .titleUp {
    font-weight: bold;
    text-decoration: none;  
    font-size: 8vh;
    text-align:center;
  }
  
  .blankSpaceUp {
    height: 10vh;
    background-color: #ffcfb3;
  }
  
  .blankSpaceUpBottom{
    text-align:center;
    height: 2vh;
    width: 50vw;
    margin: 0px 0px 0px 25vw;
    border-bottom-style: solid;
    border-bottom-color: black;
    border-bottom-width: 2px;
    background-color: #ffcfb3;
  }

  .localCard{
    text-align:center;
    height: auto;
    width: 70vw;
    margin: 0px 15vw 0px 15vw;
    padding: 10px 10px 10px 10px;
    background-color: white;
  }















  

