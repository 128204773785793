/* Applies to the entire body of the HTML document (except where overridden by more specific
selectors). */


h1 {
  font-weight: bold;
  text-decoration: underline;  
}

p {
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  font-size: 2vh;
}

  /* Expericnes styles */
  .backGroundPro {
    background-color: #ffc2d1;
    height: 100%;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
  }

  .blankGap {
    padding-top: 50px;
  }

  .backGroundSizePro {
    height: 93.1vh;
    background-color: #ffc2d1;
  }

  .backColorPro{background-color: #ffc2d1;}

  .textFont {
    font-family: "Courier New", Courier, monospace;
  }

  .blankSpacePro {
    height: 10vh;
    background-color: #ffc2d1;
  }


  .titlePro {
    font-weight: bold;
    text-decoration: none;  
    font-size: 8vh;
    text-align:center;
  }

  .overFlowAllow {
    overflow:visible
  }
  
  .blankSpacePro {
    height: 10vh;
    background-color: #ffc2d1;
  }
  
  .blankSpaceProBottom{
    text-align:center;
    height: 2vh;
    width: 50vw;
    margin: 0px 0px 0px 25vw;
    border-bottom-style: solid;
    border-bottom-color: black;
    border-bottom-width: 2px;
    background-color: #ffc2d1;
  }















  

