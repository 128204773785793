/* Applies to the entire body of the HTML document (except where overridden by more specific
selectors). */
/* Expericnes styles */

.titleGal {
  font-weight: bold;
  text-decoration: none;  
  font-size: 8vh;
  text-align:center;
}

.backGroundGal {
  background-color: #fee1e8;
  height: 100%;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.backGroundSizeGal {
  height: 93.1vh;
  background-color: #fee1e8;
}

.backColorGal {
  background-color: #fee1e8;
}

.textFont {
  font-family: "Courier New", Courier, monospace;
}

.blankSpaceGallery {
  height: 10vh;
  background-color: #fee1e8;
}

.sidePadding {
  width: 11vw;
  background-color: #fee1e8;
}


.blankSpaceGalleryBottom{
  text-align:center;
  height: 2vh;
  width: 50vw;
  margin: 0px 0px 0px 25vw;
  border-bottom-style: solid;
  border-bottom-color: black;
  border-bottom-width: 2px;
  background-color: #fee1e8;
}


.standardBoxOver {
  height: 45vh;
  width: 100vw;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  background-color: #fee1e8;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
}

.mobileBoxOver {
  height: 100%;
  width: 100vw;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  background-color: #fee1e8;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
}

.imgBoxGallery {
  height: 30vh;
  width: 25vw;
}

.mobileimgBoxGallery {
  height: 100%;
  width: 70vw;
  margin-left: auto;
  margin-right: auto;
  background-color: #fee1e8;
}

.standardBoxMiddle {
  background-color: #fee1e8;
  margin-left: auto;
  margin-right: auto;
  height: 400px;
  width: 450px;
  padding: 4px 4px 4px 4px;
}

.divCenter{
  display: flex;
  justify-content: center;
}