/* Applies to the entire body of the HTML document (except where overridden by more specific
selectors). */
/* Expericnes styles */

.titleCon {
  font-weight: bold;
  text-decoration: none;  
  font-size: 8vh;
  text-align:center;
}

.backGroundCon {
  background-color: lightblue;
  height: 100%;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.backGroundSizeCon {
  height: 93.1vh;
  background-color: lightblue;
}

.backColorCon {
  background-color: lightblue;
}

.textFont {
  font-family: "Courier New", Courier, monospace;
}

.blankSpaceCon {
  height: 10vh;
  background-color: lightblue;
}



.blankSpaceConBottom{
  text-align:center;
  height: 2vh;
  width: 50vw;
  margin: 0px 0px 0px 25vw;
  border-bottom-style: solid;
  border-bottom-color: black;
  border-bottom-width: 2px;
  background-color: lightblue;
}

.divCenter{
  display: flex;
  justify-content: center;
}

.localCardCon{
  text-align:center;
  height: auto;
  width: 70vw;
  margin: 0px 15vw 0px 15vw;
  padding: 10px 10px 10px 10px;
  background-color: white;
}