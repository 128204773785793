/* Applies to the entire body of the HTML document (except where overridden by more specific
selectors). */


h1 {
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  text-decoration: underline;  
}

h2 {
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  font-size: 3vh;
}

p {
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  font-size: 2vh;
}

  /* Expericnes styles */
  .backGroundExp {
    background-color: #ffc2d1;
    height: 100%;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
  }

  .backGroundSizeExp {
    height: 93.1vh;
    background-color: #ffc2d1;
  }

  .backColor{background-color: #ffc2d1;}


  .blankSpace {
    height: 10vh;
    background-color: #ffc2d1;
  }

  .blankGap {
    padding-top: 50px;
  }

  /* top, right, bottom, left */
  .standardBox {
    height: 40vh 100%;
    width: 70vw;
    padding: 0vh 0vw 0vh 0vw;
    margin: 0px 0px 0px 0px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
  }

  .boxShadow{
    box-shadow: 8px 8px 8px 4px rgba(0, 0, 0, 0.2);
    border: 8px 8px 8px 4px black;
  }

  .imgBoxSize{
    height: auto;
    width: 20vw;
    margin: 0px 1vh 0px 0px;

  }

  .imgBoxSizeMobile{
    height: auto;
    width: 70vw;
    margin: 0px 1vh 0px 0px;
  }

  .textSpace{
    margin: 0px 1vw 0px 1vw;
  }

  .borderIssue {
    outline: 10px solid transparent;
    padding: 10px;
  }

  .textSizeMobile{
    height: auto;
    width: 75vw;

  }



  .titleExp {
    font-weight: bold;
    text-decoration: none;  
    font-size: 8vh;
    text-align:center;
  }
  
  .blankSpaceExp {
    height: 10vh;
    background-color: #ffc2d1;
  }
  
  .blankSpaceExpBottom{
    text-align:center;
    height: 2vh;
    width: 50vw;
    margin: 0px 0px 0px 25vw;
    border-bottom-style: solid;
    border-bottom-color: black;
    border-bottom-width: 2px;
    background-color: #ffc2d1;
  }













  

