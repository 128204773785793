/* Applies to the entire body of the HTML document (except where overridden by more specific
selectors). */


  /* Applies to all elements with <... class="someclass"> specified. */
  /* NavBar Styles */
  .body {
    width: 100%;
    margin: 0;
    border: '2px solid green';
  }

  .Button {
    padding: 20px;
    font-size: 35px;
    font-weight: normal;
    margin-top: 5px;
  }

  .navBarFont {
    font-family: "Courier New", Courier, monospace;
    font-weight: bold;
    font-size: 20px;
  }
  .bottomborder {
    border-bottom-style: solid;
    border-bottom-color: black;
    border-bottom-width: 0.2%;
  }
  .fullWidth {width: 100%};
  .noPad {padding: 0px 0px 0px 0px;}
  .noMar {margin: 0px 0px 0px 0px;}

  .navSize{ height: 4.8vh;}

  .linkedSize{ 
    height: 40px; 
    width: auto;
    padding: 0.1vh 10px 0px 0px;
    float: right;
  }
  

  /* Landing Page styles */
  .backGround {
    background-color: lightblue;
    height: 100%;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
  }

  .backGroundColorLand {
    background-color: lightblue;
  }

  .blankSpaceLand{
    background-color: lightblue;
    height: 10%;
  }

  .backGroundSize {
    height: 94vh;
  }

  /* top, right, bottom, left */
  
  .textAndrewLocation {
    padding: 4% 10% 0% 10%;
    font-family: "Courier New", Courier, monospace;
    font-weight: bold;
    font-size: 10vh;
    background-color: lightblue;
    opacity: 0;
    transform: translateY(3rem);
    animation: fadeInUp 3s ease 2.5s forwards;

  }

  .textTitleLocation {
    padding: 0% 10% 0% 10%;
    font-family: "Courier New", Courier, monospace;
    font-weight: bold;
    font-size: 5vh;
    background-color: lightblue;
    opacity: 0;
    transform: translateY(3rem);
    animation: fadeInUp 3s ease 3s forwards;
  }


  /* Animaiton 1*/
  .textHiLocation {
    margin: 0% 0% 0% 10%;
    font-family: "Courier New", Courier, monospace;
    font-weight: bold;
    font-size: 24vh;
    position: relative;
    width: max-content;
  }

  .textHiLocation::before,
  .textHiLocation::after{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .textHiLocation::before{
    background: lightblue;
    animation: 
      typewriter 2s steps(2) 250ms forwards;
  }

  
  .textHiLocation::after{
    width: 0.125em;
    background: black;
    animation: 
      typewriter 2s steps(2) 250ms forwards,
      blink 750ms steps(2) infinite;
  }

  /* Animaiton 2*/
  .textHiThereLocation {
    margin: 0% 0% 0% 10%;
    font-family: "Courier New", Courier, monospace;
    font-weight: bold;
    font-size: 24vh;
    position: relative;
    width: max-content;
  }

  .textHiThereLocation::before,
  .textHiThereLocation::after{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .textHiThereLocation::before{
    background: lightblue;
    animation: 
      typewriter 2s steps(10) 250ms forwards;
  }

  .textHiThereLocation::after{
    width: 0.125em;
    background: black;
    animation: 
      typewriter 2s steps(10) 250ms forwards,
      blink 750ms steps(10) infinite;
  }

  @keyframes typewriter {
    to {
      left: 100%;
    }
  }

  @keyframes blink {
    to {
      background: transparent;
    }
  }

  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }











  

