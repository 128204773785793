/* Applies to the entire body of the HTML document (except where overridden by more specific
selectors). */


h1 {
  font-weight: bold;
  text-decoration: underline;  
}

h2 {
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  font-size: 4vh; 
}

h3 {
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  font-size: 3vh;
}

p {
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  font-size: 2vh;
}

.bolder {
  font-weight: bold;
}

.textChange {
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  font-size: 2.4vh;
  color: black;
}

  /* Expericnes styles */
  .backGroundScrap {
    background-color: #ffcfb3;
    height: 100%;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
  }

  .backGroundSizeUpScrap {
    height: 93.1vh;
    background-color: #ffcfb3;
  }

  .backColorScrap{background-color: #ffcfb3;}

  .textFont {
    font-family: "Courier New", Courier, monospace;
  }

  .blankSpaceScrap {
    height: 10vh;
    background-color: #ffcfb3;
  }

  .middleSquish {
    padding: 0vw 10vw 0vw 10vw;
  }

  .scrapContainor{
    display: flex;
    justify-content: center;
    flex-direction: row;
  }

  .scrapContainorRow{
    display: flex;
    flex-direction: row;
  }
  .centerScrap{
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .centerScrapWidth{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 20vw;
  }

  .centerScrapWrite{
    margin-top: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 80vw;
  }

  .titleScrap {
    font-weight: bold;
    text-decoration: none;  
    font-size: 8vh;
    text-align:center;
  }
  
  .blankSpaceScrap {
    height: 10vh;
    background-color: #ffcfb3;
  }
  
  .blankSpaceScrapBottom{
    text-align:center;
    height: 2vh;
    width: 50vw;
    margin: 0px 0px 0px 25vw;
    border-bottom-style: solid;
    border-bottom-color: black;
    border-bottom-width: 2px;
    background-color: #ffcfb3;
  }

  .inputBorder{
    border: 3px solid black;
  }

  .letterCardTile{
    background-color: white;
    border: 5px solid black;
    height: 80px;
    width: 80px;
    margin: 5px;
  }

  .letterTile {
    font-weight: bold;
    font-size: 50px;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 10px;
    letter-spacing: 0em;
    line-height: 0px;
  }

  .letterNum {
    font-weight: bold;
    font-size: 20px;
    display: flex;
    padding-right: 5px;
    justify-content: right;
  }
